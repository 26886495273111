import { CacheLookupPolicy, Configuration, RedirectRequest, SilentRequest } from '@azure/msal-browser';

import Environment from 'src/environments';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: Environment.aadClientIdForWebUser,
        authority: 'https://login.microsoftonline.com/microsoft.onmicrosoft.com/',
        redirectUri: `${window.location.origin}`,
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const RedirectAuthRequest: RedirectRequest = {
    scopes: ['openid', 'user.read'],
};

export const SilentAuthRequest: SilentRequest = {
    scopes: ['openid', 'user.read'],
    forceRefresh: false,
    cacheLookupPolicy: CacheLookupPolicy.Default,
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
